import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'


const CustomForm = ({ formik, children, title = '', subTitle = '', primaryButtontext = '' }) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const { t } = useTranslation()

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6">
          <h2 className="h4 mb-1">{title}</h2>
        </div>
        <div className="col-md-6 text-md-end">
          <a className="link" href={`${process.env.REACT_APP_HOST_URL}/custom/assets/Files/creditapplication.pdf`} target="_blank" rel="noreferrer noopenner">
            {t('frontend.account.downloadCreditApplication')}
          </a>
        </div>
      </div>
      <p
        className="font-size-sm text-muted mb-4"
        onClick={eventHandlerForWSIWYG}
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        {children}
        <hr className="my-4" />
        <div className="tnc_note">
          <p>{t('frontend.account.tnc_note')}</p>
        </div>
        <div>
          <input
            type="checkbox"
            id="tnc"
            name="tnc"
            checked={formik.values.tnc}
            onChange={e => formik.setFieldValue('tnc', e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="tnc">Accept Terms & Conditions</label>
          {formik.touched.tnc && formik.errors.tnc && (<span className="form-error-msg">{formik.errors.tnc}</span>)}
        </div>
        <hr className="my-4" />
        <div className="row">
          <CustomInput formik={formik} token="date" label="Date" type="date" wrapperClasses='col-md-4' />
          <CustomInput formik={formik} token="fullName" label="Full Name" wrapperClasses='col-md-4' />
          <CustomInput formik={formik} token="title" label="Title" wrapperClasses='col-md-4' />
        </div>

        <div className="d-flex pt-4 justify-content-between">
          <Link className="btn btn-secondary" to="/my-account/createaccount">{"Back to Create Account"}</Link>
          <button className="btn btn-primary" type="submit">
            {primaryButtontext}
          </button>
        </div>
      </form>
    </>
  );
};

const CustomInput = ({ required = false, formik, token = '', label = '', wrapperClasses = '', type = 'text', showErrors = true }) => {
  const wrapperClass = wrapperClasses || 'col-md-6';
  // const handleChange = (e) => {
  //   const value = type === 'number' ? e.target.value.replace(/\D/g, '') : e.target.value;
  //   formik.setFieldValue(token, value);
  // };
  return (
    <div className={`${wrapperClass} form-group`}>
      <label className="control-label" htmlFor={token}>
        {label}
      </label>
      <input className="form-control" type={type} id={token} value={formik.values[token]} onChange={formik.handleChange} onBlur={formik.handleBlur} />
      {showErrors && formik.touched[token] && formik.errors[token] && <span className="form-error-msg">{formik.errors[token]}</span>}
    </div>
  );
};

const CustomSelect = ({ formik, token, label, options = [], wrapperClasses = 'col-md-6' }) => (
  <div className={`${wrapperClasses} form-group`}>
    <label htmlFor={token}>{label}</label>
    <select
      id={token}
      name={token}
      value={formik.values?.[token] || ''}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      className="form-control"
    >
      <option value="" disabled>Select</option>
      {options.map((opt, index) => <option key={index} value={opt}>{opt}</option>)}
    </select>
    {formik.touched?.[token] && formik.errors?.[token] && (
      <span className="form-error-msg">{formik.errors[token]}</span>
    )}
  </div>
);


const CustomLayout = ({ children }) => {
  return (
    <div className="container py-4 py-lg-5 my-4">
      <div className="row d-flex justify-content-center">
        <div className="col-md-8 col-lg-8 col-sm-12"> {/* Increased width */}
          <div className="card box-shadow">
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CustomForm, CustomInput, CustomLayout, CustomSelect };
