import { useTranslation } from 'react-i18next'

const CashDetails = ({ cashPayment, hideHeading }) => {
  const { t } = useTranslation()
  
  const { purchaseOrderNumber, paymentMethod, paymentMethod_paymentMethodName } = cashPayment || {}

  return (
    <>
      {!hideHeading && <h3 className="h6">{t('frontend.checkout.payment_method')}</h3>}      
      <p>
        {paymentMethod?.paymentMethodName && <em>{paymentMethod.paymentMethodName}</em>}
        {paymentMethod_paymentMethodName && <em>{paymentMethod_paymentMethodName}</em>}
        {purchaseOrderNumber && <p># {purchaseOrderNumber}</p>}
      </p>
    </>
  )
}

export { CashDetails }
