import { CustomLayout } from "../../components/Forms/CustomForm"
import { OnlineCreditForm } from "../../components/Forms/OnlineCreditForm"


const onlineCreditForm = () => {
  return (
    <CustomLayout>
      <OnlineCreditForm />
    </CustomLayout>
  )
}

export default onlineCreditForm