const TermPaymentDetails = ({ termPayment }) => {
  const { paymentMethod } = termPayment

  return (
    <>
      <p>
        <em>{paymentMethod?.paymentMethodName}</em>
      </p>
      {termPayment?.purchaseOrderNumber && (
            <p># {termPayment?.purchaseOrderNumber}</p>
      )}
    </>
  )
}

export { TermPaymentDetails }