import { AccountContent } from '@ten24/slatwallreactlibrary/components'
import { AccountLayout } from '../'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFormatDateTime, useFormatCurrency, useGetAllOrders } from '@ten24/slatwallreactlibrary/hooks/'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'

const AccountRecentOrders = () => {
  let [orders, setRequest] = useGetAllOrders()
  const [formatCurrency] = useFormatCurrency({})
  const [formateDate] = useFormatDateTime()

  useEffect(() => {
    let didCancel = false
    if (!orders.isFetching && !orders.isLoaded && !didCancel) {
      setRequest({ ...orders, isFetching: true, isLoaded: false, params: {}, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [orders, setRequest])

  let agilityOrderNumber = ''
  if(orders.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length > 0 && orders.data.ordersOnAccount[0].remoteID.length > 1) agilityOrderNumber = `(${orders.data.ordersOnAccount[0].remoteID})`
  
  // add dynamic class to the purchase order section i.e remove top padding if purchase order number does not exist
  let purchaseOrderClass = orders?.data?.ordersOnAccount?.length > 0 && orders.data.ordersOnAccount[0].orderPayments_purchaseOrderNumber.trim() !== ""
  ? "p-3"
  : "pb-3 pr-3 pl-3";

  
  return (
    <>
      {orders.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length > 0 && (
        <>
          <h3 className="h4 mt-5 mb-3">Most Recent Order</h3>
          <div className="row bg-lightgray rounded align-items-center justify-content-between mb-4">
            <div className="col-xs-4 p-3">
              <h6>Order {orders.data.ordersOnAccount[0].orderNumber}{agilityOrderNumber}</h6>
              <span>{formateDate(orders.data.ordersOnAccount[0].createdDateTime)}</span>
            </div>
          <div className={`col-xs-4 ${purchaseOrderClass}`}>
              <h6>PO #</h6>
              <span>{orders.data.ordersOnAccount[0].orderPayments_purchaseOrderNumber}</span>
            </div>
            <div className="col-xs-3 p-3">
              <h6>Status</h6>
              <span>{orders.data.ordersOnAccount[0].orderStatusType_typeName}</span>
            </div>
            <div className="col-xs-3 p-3">
              <h6>Order Total</h6>
              <span>{formatCurrency(orders.data.ordersOnAccount[0].calculatedTotal)}</span>
            </div>
            <div className="p-3">
              <Link className="btn btn-outline-secondary" to={`/my-account/orders/${orders.data.ordersOnAccount[0].orderID}`}>
                View
              </Link>
            </div>
          </div>
          <Link className="btn btn-primary" to={`/my-account/orders`}>
            View All Orders
          </Link>
        </>
      )}
    </>
  )
}

const SalesRepresentative = () => {
  const user = useSelector(state => state.userReducer)

  return (
    <>
      {isAuthenticated() && !user.isFetching && user.accountID.length > 0 && user.salesAgent.length > 0 && (
        <div className="container bg-default box-shadow-lg rounded-lg px-4 py-3 mb-5">
          <div className="px-lg-3">
            <small className="py-4 mb-10">Your sales rep</small>
            <h6>{user.salesAgent}</h6>
            <a href={`mailto:${user.salesAgentEmail}`}>{user.salesAgentEmail}</a>
            <br />
            <a href={`tel:${user.salesAgentPhone}`}>{user.salesAgentPhone}</a>
          </div>
        </div>
      )}
    </>
  )
}

const AccountOverview = ({ customBody, crumbs, title, contentTitle }) => {
  return (
    <AccountLayout crumbs={crumbs} title={title}>
      <AccountContent contentTitle={contentTitle} customBody={customBody} />
      <SalesRepresentative />
      <AccountRecentOrders />
    </AccountLayout>
  )
}

export { AccountOverview }
