import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { SlatwallApiService } from '@ten24/slatwallreactlibrary/services'
import { useHistory, Link } from 'react-router-dom'
import * as Yup from 'yup'
import { getErrorMessage } from '@ten24/slatwallreactlibrary/utils'
import { useSelector } from 'react-redux'
import { CustomForm, CustomInput, CustomSelect } from './CustomForm'
import { useState } from 'react'

const OnlineCreditForm = () => {
  
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const contactFormID = useSelector(state => state.configuration.forms.onlineCreditForm)
  const history = useHistory()
  const [ principalCount, setPrincipalCount ] = useState(1);
  const [ creditReferenceCount, setCreditReferenceCount ] = useState(1);
  const fieldNames = ['Name', 'StreetAddress', 'City', 'State', 'PostalCode', 'Phone', 'Email'];
  const creditReferenceFieldNames = ['streetAddress','businessName', 'city', 'state', 'postalCode', 'phone', 'email'];
  const ownershipArr = ["Corporation", "Partnership", "Individual"];
  const taxStatus = [
    { name: 'Taxable', value: 'taxable' },
    { name: 'Exempt', value: 'taxexempt', note: '(Copy of Exempt Certificate required)' },
  ];

  const handleOwnershipChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('ownership', value);
  };

  const addAnotherPrincipal = (event) => {
    event.preventDefault();
    if (principalCount < 3) {
      setPrincipalCount(principalCount + 1);
    }
  };

  const removeLastPrincipal = (event) => {
    event.preventDefault();
    if (principalCount > 1) {
      const fieldsToClear = fieldNames;
      fieldsToClear.forEach(field => formik.setFieldValue(`principal${principalCount}${field}`, ''));
      setPrincipalCount(principalCount - 1);
    }
  };

  const handleInputChange = (index, field, value) => {
    const fieldName = `principal${index + 1}${field}`;
    formik.setFieldValue(fieldName, value);
  };

  const addAnotherCreditReference = (event) => {
    event.preventDefault();
    if (creditReferenceCount < 3) {
      setCreditReferenceCount(creditReferenceCount + 1);
    }
  };

  const removeLastCreditReference = (event) => {
    event.preventDefault();
    if (creditReferenceCount > 1) {
      const fieldsToClear = creditReferenceFieldNames;
      fieldsToClear.forEach(field => formik.setFieldValue(`${field}${creditReferenceCount}`, ''));
      setCreditReferenceCount(creditReferenceCount - 1);
    }
  };

  const handleInputChangeCreditReference = (index, field, value) => {
    const fieldName = `${field}CR${index + 1}`;
    formik.setFieldValue(fieldName, value);
  };


  // eslint-disable-next-line no-unused-vars
  const signupSchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
    emailAddress: Yup.string().email('Invalid email').required('Required'),
    // Billing Address
    bStreetAddress1: Yup.string().required('Required'),
    bCity: Yup.string().required('Required'),
    bState: Yup.string().required('Required'),
    bPostalCode: Yup.string().required('Required'),
    // AP Contact
    ApFirstName: Yup.string().required('Required'),
    ApLastName: Yup.string().required('Required'),
    ApPhoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
    ApEmail: Yup.string().email('Invalid email').required('Required'),
    // Ownership & Principal
    ownership: Yup.string().required('Required'),
    principal1Name: Yup.string().required('Required'),
    principal1StreetAddress: Yup.string().required('Required'),
    principal1City: Yup.string().required('Required'),
    principal1State: Yup.string().required('Required'),
    principal1PostalCode: Yup.string().required('Required'),
    principal1Phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
    principal1Email: Yup.string().email('Invalid email').required('Required'),
    // Credit References
    businessNameCR1: Yup.string().required('Required'),
    streetAddressCR1: Yup.string().required('Required'),
    cityCR1: Yup.string().required('Required'),
    stateCR1: Yup.string().required('Required'),
    postalCodeCR1: Yup.string().required('Required'),
    phoneCR1: Yup.string().required('Required'),
    emailCR1: Yup.string().required('Required'),
    // Tax
    taxStatus: Yup.string().required('Required'),
    // Shipping Address
    shippingStreetAddress1: Yup.string().required('Required'),
    shippingStreetAddress2: Yup.string(),
    shippingCity: Yup.string().required('Required'),
    shippingState: Yup.string().required('Required'),
    shippingPostalCode: Yup.string().required('Required'),
    // Terms and Conditions
    tnc: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('Required'),
    date: Yup.date().required('Required'),
    fullName: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      formResponse: {
        formID: contactFormID,
      },
      context: 'addFormResponse',
      companyName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      bCity: '',
      bState: '',
      bPostalCode: '',
      ApFirstName: '',
      ApLastName: '',
      ApPhoneNumber: '',
      ApEmail: '',
      ownership: [],
      taxStatus: '',
      shippingStreetAddress1: '',
      shippingStreetAddress2: '',
      shippingCity: '',
      shippingState: '',
      shippingPostalCode: '',
      tnc: false,
      date: '',
      fullName: '',
      title: '',
    },
    validateOnChange: false,
    validationSchema: signupSchema,
    onSubmit: values => {
      SlatwallApiService.content.addFormResponse(values).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          if (!response.success().failureActions.length) {
            toast.success('Credit Form Saved Successfully!')
            if (history.location.search.includes('redirect')) {
              history.replace(`/my-account${history.location.search}`)
            } else {
              history.replace(`my-account/createAccount`)
            }
          }
        } else {
          toast.error('Error')
        }
      })
    },
  })
  return (
    <CustomForm formik={formik} primaryButtontext="Save Credit Form" title="Online Credit Form">
      <div className="row">
        <CustomInput formik={formik} token="companyName" label="Company Name" wrapperClasses="col-md-12" />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="firstName" label="First Name" wrapperClasses="col-md-6" />
        <CustomInput formik={formik} token="lastName" label="Last Name" wrapperClasses="col-md-6" />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="phoneNumber" label="Phone Number" type="phone" wrapperClasses="col-md-6" />
        <CustomInput formik={formik} token="emailAddress" label="Email Address" type="email" wrapperClasses="col-md-6" />
      </div>


      <div className="row mt-4">
        <h2 className="h5 mb-1 col-md-6">Billing Address</h2>
      </div>
      <div className="row">
        <CustomInput formik={formik} token="bStreetAddress1" label="Street Address 1" wrapperClasses='col-md-12' />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="bStreetAddress2" label="Street Address 2" wrapperClasses='col-md-6' />
        <CustomInput formik={formik} token="bCity" label="City" wrapperClasses='col-md-6' />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="bState" label="State" wrapperClasses='col-md-6' />
        <CustomInput formik={formik} token="bPostalCode" label="Postal Code" type="number" wrapperClasses='col-md-6' />
      </div>


      <div className="row mt-4">
        <h2 className="h5 mb-1 col-md-6">AP Contact (If different than above)</h2>
      </div>
      <div className="row">
        <CustomInput formik={formik} token="ApFirstName" label="First Name" wrapperClasses='col-md-6' />
        <CustomInput formik={formik} token="ApLastName" label="Last Name" wrapperClasses='col-md-6' />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="ApPhoneNumber" label="Phone Number" wrapperClasses='col-md-6' />
        <CustomInput formik={formik} token="ApEmail" label="Email" wrapperClasses='col-md-6' />
      </div>

      <div className="row mt-4">
        <h2 className="h5 mb-1 col-md-6">Ownership</h2>
      </div>
      <div className="row">
        <CustomSelect formik={formik} token="ownership" label="Ownership Type" options={ownershipArr} onChange={handleOwnershipChange} wrapperClasses="col-md-6 mb-4" />
      </div>
      <div>
        {[...Array(principalCount)].map((_, index) => (
          <div key={index} >
            <h6>Principal {index + 1}</h6>
            <div className="row">
              {fieldNames.map((field) => (
                <CustomInput
                  key={`${index}-${field}`}
                  formik={formik}
                  token={`principal${index + 1}${field}`}
                  label={field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  value={formik.values[`principal${index + 1}${field}`] || ''}
                  onChange={(e) => handleInputChange(index, field, e.target.value)}
                />
              ))}
              <div className="col-md-6 form-group">
                <div className="d-flex pt-4 gap-4">
                  {index === principalCount - 1 && principalCount < 3 && (
                    <Link className="btn btn-secondary w-50 mr-4" onClick={addAnotherPrincipal}>
                      + Add Principal
                    </Link>
                  )}
                  {principalCount > 1 && index === principalCount - 1 && (
                    <Link className="btn btn-secondary w-50" onClick={removeLastPrincipal}>
                      Close
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-4">
        <h2 className="h5 mb-3 col-md-12">Tax Status</h2>
      </div>
      <div>
      {taxStatus.map(({ name, value, note }) => (
                <div key={value} className="form-check form-check-inline custom-control custom-radio d-inline-flex">
                    <input
                        name="taxStatus"  // Correct name matching validation schema
                        className="custom-control-input"
                        type="radio"
                        id={value}
                        value={value}
                        onChange={formik.handleChange}
                        checked={formik.values.taxStatus === value} // Corrected key
                    />
                    <label className="custom-control-label" htmlFor={value}>
                        {name} {note && <span className="text-gray-500 text-xs">{note}</span>}
                    </label>
                </div>
            ))}

            {formik.errors.taxStatus && <span className="form-error-msg">{formik.errors.taxStatus}</span>}
      </div>

      <div className="row mt-4">
        <h2 className="h5 mb-3 col-md-12">Credit References</h2>
      </div>

      {/* Reiterate over each creditReferenceFieldNames to generate dynamic field names and lables  */}
      <div>
        {[...Array(creditReferenceCount)].map((_, index) => (
          <div key={index} >
            <h6>Reference {index + 1}</h6>
            <div className="row">
              {creditReferenceFieldNames.map((field) => (
                <CustomInput
                  key={`${index}-${field}`}
                  formik={formik}
                  token={`${field}CR${index + 1}`}
                  label={field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  value={formik.values[`${field}CR${index + 1}`] || ''}
                  onChange={(e) => handleInputChangeCreditReference(index, field, e.target.value)}
                />
              ))}
              <div className="col-md-6 form-group">
              <div className="d-flex pt-4 gap-4">
                  {index === creditReferenceCount - 1 && creditReferenceCount < 3 && (
                    <Link className="btn btn-secondary w-60 mr-5" onClick={addAnotherCreditReference}>
                      + Add Reference
                    </Link>
                  )}
                  {creditReferenceCount > 1 && index === creditReferenceCount - 1 && (
                    <Link className="btn btn-secondary w-50" onClick={removeLastCreditReference}>
                      Close
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-4">
        <h2 className="h5 mb-3 col-md-12">Shipping Address (If different than Billing Address)</h2>
      </div>
      <div className="row">
        <CustomInput formik={formik} token="shippingStreetAddress1" label="Street Address 1" />
        <CustomInput formik={formik} token="shippingStreetAddress2" label="Street Address 2" />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="shippingCity" label="City" />
        <CustomInput formik={formik} token="shippingState" label="State" />
      </div>
      <div className="row">
        <CustomInput formik={formik} token="shippingPostalCode" label="Postal Code" />
      </div>

    </CustomForm >

  )
}
export { OnlineCreditForm }
