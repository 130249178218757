import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPayment } from '@ten24/slatwallreactlibrary/actions/'
import { FulfilmentAddressSelector } from '../Fulfilment/FulfilmentAddressSelector'
import { addNewAccountAddress } from '@ten24/slatwallreactlibrary/actions'
import { billingAccountAddressSelector, orderPayment } from '@ten24/slatwallreactlibrary/selectors'
import { useTranslation } from 'react-i18next'



const Cash = ({ method }) => {
  const dispatch = useDispatch()
  const billingAddress = useSelector(billingAccountAddressSelector)
  const { purchaseOrderNumber } = useSelector(orderPayment)
  const [termOrderNumber, setTermOrderNumber] = useState(purchaseOrderNumber || '')
  const { t } = useTranslation()

  return (
    <>
        <div className="row mb-3">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="purchaseOrderNumber">{t('frontend.checkout.payment.po.number.optional')}</label>
            <input
              className="form-control"
              type="text"
              id="purchaseOrderNumber"
              value={termOrderNumber}
              onChange={e => {
                e.preventDefault()
                setTermOrderNumber(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <FulfilmentAddressSelector
        isShipping={false}
        addressTitle={'Billing Address'}
        selectedAccountID={billingAddress}
        onSelect={value => {
          dispatch(
            addPayment({
              accountAddressID: value,
              saveShippingAsBilling: 0,
              newOrderPayment: {
                purchaseOrderNumber: termOrderNumber,
                paymentMethod: {
                  paymentMethodID: method,
                },
              },
            })
          )
        }}
        onSave={values => {
          dispatch(addNewAccountAddress({ ...values }))
        }}
      />
    </>
  )
}
export { Cash }
